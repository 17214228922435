import React from 'react';
import './App.css';
import Grid from '../Grid/Grid';


class App extends React.Component {
  render() {
    return (
      <Grid />
    );
  }
}

export default App;
