import image0 from './drqwn.jpg';
import image1 from './101408363_270443547435786_4137990265715556352_n.jpg';
import image2 from './Stillworking.jpg';
import image3 from './IMG_20150525_193944.jpg';
import image4 from './101669038_262781221662914_4656580134751436800_n.jpg';
import image5 from './95065697_248924486467843_8031107390571020288_n.jpg';
import image6 from './101881876_247849379833376_1124300297812836352_n.jpg';
import image7 from './102738036_207621113591546_610448375777587681_n.jpg';
import image8 from './102279743_1605782456251897_180731914993447595_n.jpg';
import image9 from './101207719_2934567666663069_4981561480903131136_n.jpg';
import image10 from './small.jpg';
import image11 from './IMG_20160408_200839_949.jpg';
import image12 from './the boys edited.jpg';
import image13 from './IMG_3052.JPG';
import image14 from './IMG_0698.JPG';
import image15 from './IMG_3075.JPG';
export default [{id : 0, content : image0}, {id : 1, content : image1}, {id : 2, content : image2}, {id : 3, content : image3}, {id : 4, content : image4}, {id : 5, content : image5}, {id : 6, content : image6}, {id : 7, content : image7}, {id : 8, content : image8}, {id : 9, content : image9}, {id : 10, content : image10}, {id : 11, content : image11}, {id : 12, content : image12}, {id : 13, content : image13}, {id : 14, content : image14}, {id : 15, content : image15}];